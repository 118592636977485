html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 110%;
}

body {
  display: flex;
  flex-direction: column;
}

table {
  border-collapse: collapse;
}